import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import SEO from '../components/seo';
import Compact from '../images/compact.png';
import MarsAtmosphere from '../images/mars-atmosphere.jpeg';
import MarsDome from '../images/hi-seas.jpeg';
import MarsSurface from '../images/mars-surface.png';
import { graphql } from 'gatsby';
import Img from "gatsby-image";
import MarsOpenGraphImage from '../images/mars-banner.jpg';
export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "mars-banner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Mars Isn't Planet B" description="Cold, barren, and lifeless, the Red Planet is no substitute for the Blue Marble." image={'https://storybook.earth' + MarsOpenGraphImage} pageCanonical={'https://storybook.earth/mars-isnt-planet-b'} mdxType="SEO" />
    <Img className="story-header-gatsby-img" fluid={props.data.imageOne.childImageSharp.fluid} mdxType="Img" />
    <div className="story-content">
      <p className="txt-low-emph txt-center">Cold, barren, and lifeless, the Red Planet is no substitute for the Blue Marble.</p>
      <div className="txt-center mvxxl">
    <h1 className="phs">Mars Isn't Planet B</h1>
    <div className="mtm">
        <p className="mbn title-font">By Mike McDearmon</p>
        <p className="mtn txt-low-emph txt-center">Jan 5, 2022 | 5 min read</p>
    </div>
      </div>
      <p>{`Elon Musk says he wants humanity to be a multi-planetary civilization within our lifetime, starting with the red planet, Mars. Jeff Bezos envisions a similar future in which humans dwell in colonies on the moon as well as city-sized space stations. Both billionaires cite similar motivations: Earth’s population is ballooning out of control, and our collective thirst for finite resources will lead us to one inevitable option for survival—find new homes elsewhere `}<span className="sup">{`[1]`}</span><span className="sup">{`[2]`}</span>{`.`}</p>
      <p>{`After Earth, Mars is the closest thing to a habitable place in our solar system. But that’s not saying much. Imagine the bitter cold of Antarctica paired with the desolation of the Sahara and you’d still be giving it far too much credit. The technology required to safely transport astronauts on missions of scientific exploration to Mars is conceivable in the near future, but Musk’s vision of a thriving city of a million people is not. Furthermore, the idea that space represents a kind of hedge against humanity outgrowing Earth is more of a distraction than a meaningful solution. Far more people (and countless other species) would benefit from preserving the ideal home we already have than from elaborate schemes which, at best, could take only a tiny fraction of us somewhere much worse.`}</p>
      <div className="mtxxl txt-center">
        <div className="planet-viz-container">
    <div className="planets-container">
        <div className="earth-container">
            <div className="earth-viz"></div>
        </div>        
        <div className="mars-container">
            <div className="mars-viz"></div>
        </div>
    </div>
        </div>
        <p className="phxl mbxxl caption">Earth (left), as captured by Apollo 17 <span className="sup">[3]</span> and Mars (right) photographed by NASA's Hubble Space Telescope in 2007 <span className="sup">[4]</span>. Mars is roughly half the size of Earth, with a cold and unprotected surface that can't support life as we know it.</p>
      </div>
      <p>{`Let’s look at what might await humans on Mars. The average surface temperature is -81 degrees fahrenheit (-63 C) with an atmosphere composed of 96% carbon dioxide `}<span className="sup">{`[5]`}</span>{`. Aside from being unbreathable, the Martian atmosphere is both too cold and too thin to support liquid water `}<span className="sup">{`[6]`}</span>{`. It's so thin that the lack of atmospheric pressure reduces the boiling point of water such that, if unprotected, human blood would boil even at ambient surface temperatures `}<span className="sup">{`[7]`}</span>{`. Mars also lacks the natural protections we take for granted on Earth against the harsh radiation environment of space, so people would need to remain in protected shelters or suits indefinitely to prevent radiation poisoning `}<span className="sup">{`[8]`}</span>{`.`}</p>
      <p>{`NASA astronauts must undergo extensive training in order to succeed amidst the pressures of life in extreme environments and confinement with their crewmates. They also need engineering skills to use and repair complex equipment—the longer the mission, the greater the chance that both their technical and coping skills will be put to the test `}<span className="sup">{`[9]`}</span>{`. In order to visit Mars, the shortest conceivable round trip would last well over a year, far longer than any astronaut has been in deep space (for comparison, Apollo 11 lasted just over 8 days) `}<span className="sup">{`[10]`}</span>{`. Without incredible advances in technology making spaceflight safer and more approachable, it’s hard to imagine any trips to the red planet being within reach of the general public.`}</p>
      <div className="mtxxl txt-center">
        <img src={MarsDome} alt="" width="100%" height="100%" />
        <p className="phxl mbxxl caption">HI-SEAS, a University of Hawai‘i at Manoa research program funded by NASA, has run several missions designed to prepare future astronauts for missions to Mars. The longest mission lasted a year, substantially shorter than a real trip to the red planet, and isolated a crew of 6 in a small shelter designed to simulate the lifestyle future explorers might encounter. Crew members faced several interpersonal challenges, among them an increased need for dedicated alone time as the mission progressed <span className="sup">[11]</span>. (HI-SEAS Research Site at Mauna Loa/Via HI-SEAS)</p>
      </div>
      <p>{`Ever the charismatic optimist, Musk sees the brighter side: "It would be quite fun to be on Mars because you would have gravity that is about 37 percent of that of Earth, so you would be able to lift heavy things and bound around" `}<span className="sup">{`[12]`}</span>{`. It’s easy to interpret the ‘you’ in that statement as ‘you’ the reader, but as we’ve just discussed, unless you’re prepared to both fix the spaceship you flew in on and cope with years of total enclosure, ‘you’ are not qualified. Having said that, if Musk has his way, It’ll be up to future colonists to weigh the tradeoffs of fun in low gravity against life in cramped shelters without most of the comforts we take for granted.`}</p>
      <p>{`Musk has even used biblical metaphors to describe his mission to Mars as one of salvation: “The next really big thing is to build a self-sustaining city on Mars and bring the animals and creatures of Earth there, sort of like a futuristic Noah's ark” he told Time Magazine for his ‘2021 Person of the Year’ interview `}<span className="sup">{`[13]`}</span>{`. But Musk has a track record of big promises that don’t materialize, like the one about Tesla drivers being able to safely fall asleep at the wheel by 2019 or the other about a million robotaxis on the road by 2020 `}<span className="sup">{`[14]`}</span>{`. While his boldest predictions often don't materialize, Musk has enough successes along the way to offset his failures. The vision of a city on Mars is poised to follow suit, and even in failure Musk's achievements may be so astounding that few will notice or care when the original dream never becomes reality.`}</p>
      <div className="mtxxl txt-center">
        <img src={MarsAtmosphere} alt="" width="100%" height="100%" />
        <p className="phxl mbxxl caption">​Because the atmosphere is so thin, heat escapes Mars much more easily than on Earth. This causes an extreme temperature gradient such that if you were to stand on the Martian surface at the equator, it could be as pleasant as 70 degrees fahrenheit at your feet while simultaneously being below freezing at your head <span className="sup">[15].</span> (Mars atmosphere/Via NASA/Viking 1)</p>
      </div>
      <p>{`Not only is the promise of deliverance from overcrowding on Earth an empty one, it’s also dangerous. Space-faring tech billionaires have enormous influence on markets and public opinion, and the more they cast doubt on our ability to construct a successful future without going anywhere, the harder that mission becomes. We already have the tools and strategies required to make our civilization sustainable on Earth for billions more people today—among them are cheap renewable energy sources and regenerative agricultural practices `}<span className="sup">{`[16]`}</span>{`. To adopt these solutions both quickly and globally we need everyone, especially the most wealthy and influential among us, to be their chief investors and evangelists.`}</p>
      <p>{`Part of the allure of colonizing Mars is the blank canvas—it’s an opportunity for humanity to turn the page and do things right on an exciting new frontier. We shouldn’t assume, however, that civilization would fare any better in an environment that’s more hostile than Earth in even the most catastrophic of future climate change scenarios. Life on this planet has survived at least five major extinction events. It’s the only place we know of where life not only exists, but persists through calamity time and again.`}</p>
      <div className="mtxxl txt-center">
        <img src={MarsSurface} alt="" width="100%" height="100%" />
        <p className="phxl mbxxl caption">​What about changing the environment of Mars to be more like Earth? NASA research indicates that terraforming is not possible with available technology and that "any such efforts have to be very far into the future" <span className="sup">[15].</span> (Martian surface as seen from the Perserverance rover/Via NASA/JPL-Caltech/ASU/MSSS)</p>
      </div>
      <p>{`The fact that we humans are taking baby steps into the cosmos beyond our planet at all is one of our differentiators amongst all life on Earth. Each new mission helps add to our understanding of the universe and our place within it. Humanity is also capable of multitasking, and we can both explore other worlds while learning to live sustainably on this one. In terms of a suitable home for our species, however, there is no viable alternative to Earth. Our planet is an exceptional human habitat by every measure. We evolved here and our future, at least for the foreseeable, will be here.`}</p>
      <div className="references mvxxl">
    <strong>References</strong>
    <ol>
        <li>Joseph Guzman, <a href="https://thehill.com/changing-america/sustainability/565224-elon-musk-says-population-collapse-potentially-the-greatest" target="blank">Elon Musk says population collapse 'potentially the greatest risk to the future of civilization'</a>, The Hill, 2021</li>
        <li><a href="https://www.youtube.com/watch?v=67KZfeZyw_U" target="blank">Our Future in Space</a>, Washington Cathedral, November 10, 2021</li>
        <li>NASA Apollo 17 Crew, <a href="https://www.nasa.gov/image-feature/the-blue-marble-the-view-from-apollo-17" target="blank">The Blue Marble: The View From Apollo 17</a>, NASA, 1972</li>
        <li>NASA, ESA, the Hubble Heritage Team, J. Bell and M. Wolff, <a href="https://www.nasa.gov/mission_pages/hubble/science/hst_img_20071218.html" target="blank">The Hubble Space Telescope Sees Mars's Close Approach in 2007</a>, NASA, 2007</li>
        <li>Mars Exploration Program, <a href="https://mars.nasa.gov/all-about-mars/facts/" target="blank">Mars Facts</a>, NASA, 2021</li>
        <li>Jim Wilson, Bill Steigerwald and Nancy Jones, <a href="https://www.nasa.gov/press-release/nasas-maven-reveals-most-of-mars-atmosphere-was-lost-to-space" target="blank">NASA's MAVEN Reveals Most of Mars' Atmosphere Was Lost to Space</a>, NASA, 2017</li>
        <li>Irene Klotz, <a href="https://www.space.com/36800-five-ways-to-die-on-mars.html" target="blank">Boiling Blood and Radiation: 5 Ways Mars Can Kill</a>, Space.com, 2017</li>
        <li>Sarah Frazier, <a href="https://www.nasa.gov/feature/goddard/real-martians-how-to-protect-astronauts-from-space-radiation-on-mars" target="blank">Real Martians: How to Protect Astronauts from Space Radiation on Mars</a>, NASA, 2015</li>
        <li>Suzanne Bell and Stephen T. Vander Ark, <a href="https://www.nasa.gov/content/behavioral-health" target="blank">Promoting Psychological Readiness and Performance</a>, NASA, 2015</li>
        <li>Nicola Sarzi Amade, <a href="https://ui.adsabs.harvard.edu/abs/2010PhDT.......139S/abstract" target="blank">Mars rapid round trip mission design</a>, University of Southern California, 2010</li>
        <li>C. Heinicke, L. Poulet, J. Dunn and A. Meier, <a href="https://www.sciencedirect.com/science/article/pii/S0094576521000606" target="blank">Crew self-organization and group-living habits during three autonomous, long-duration Mars analog missions</a>, Acta Astronautica, 2021</li>
        <li>Hannah Osborne, <a href="https://www.newsweek.com/elon-musk-mars-spacex-martian-city-625994" target="blank">Elon Musk Reveals Vision for a SpaceX City on Mars</a>, Newsweek, 2017</li>
        <li>Tim Levin, <a href="https://www.businessinsider.com/elon-musk-tesla-2020-promises-goals-accomplishments-missed-deadlines-2021-1" target="blank">Time 2021 Person of the Year Elon Musk</a>, Time, 2021</li>
        <li>Molly Ball, Jeffrey Kluger and Alejandro de la Garza, <a href="https://time.com/person-of-the-year-2021-elon-musk/" target="blank">11 things Elon Musk said Tesla would accomplish in 2020, and how those promises panned out</a>, Business Insider, 2021</li>
        <li><a href="https://solarsystem.nasa.gov/planets/mars/in-depth/" target="blank">Mars in Depth</a>, NASA, 2021</li>
        <li>Drawdown Staff, <a href="https://www.drawdown.org/solutions" target="blank">Solutions</a>, Project Drawdown, 2021</li>
        <li>Bill Steigerwald and Nancy Jones, <a href="https://www.nasa.gov/press-release/goddard/2018/mars-terraforming" target="blank">Mars Terraforming Not Possible Using Present-Day Technology</a>, NASA, 2018</li>
    </ol>
      </div>
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      